.App {
  text-align: center;
  display: flex;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(79deg, #f0dd0e, #88b933 48%, #07f82f);
}

.form-container, .login-form, .register-form, .dropbtn {
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 600px) {
  .form-container {
    padding: 2rem;
    border:  2px solid rgb(62, 69, 55);
    border-radius: 10px;
    margin: 0.2rem;
}
}

/* .form-container, {
  padding: 2rem;
  border:  2px solid rgb(62, 69, 55);
  border-radius: 10px;
  margin: 0.2rem;
} */

label {
  align-items: left;
  padding: 0.5rem;
}

input, .dropbtn {
  margin: 1rem 0;
  padding: 1rem;
  border: none;
  border-radius: 10px;
}

button {
  border: none;
  margin-bottom: 10px;
  background-color: green;
  padding: 20px;
  border-radius: 10px;
  cursor: pointer;
}

.link-btn {
  background-color: none;
  color: aqua;
  /* text-decoration: underline; */
}
